import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { graphql, StaticQuery } from 'gatsby';
import styled from '@emotion/styled';

const BackgroundImgStyled = styled(BackgroundImage)`
  background-size: contain;
  width: 100%;
  max-width: 1102px;
  height: 800px;
  margin-left: auto;
  margin-right: -420px;
  margin-top: -50px;
  margin-bottom: -300px;
  z-index: -1;
`;

export const BackgroundImg = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(
            relativePath: {
              eq: "screens/Programming/FormBlock/images/background_right.png"
            }
          ) {
            childImageSharp {
              fluid(maxWidth: 1920, base64Width: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const imageData = data.desktop.childImageSharp.fluid;
        return (
          <BackgroundImgStyled
            className="background-right"
            Tag="div"
            fluid={imageData}
          />
        );
      }}
    />
  );
};
