import React from 'react';
import { Form as FormikForm, FormikProps, withFormik } from 'formik';
import { FormInput } from 'components/FormInput';
import { PhoneInput } from 'components/PhoneInput';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import arrowLeftImg from 'assets/images/next_arrow.svg';
import { CourseIds } from 'constants/courses';
import { ILocation } from 'containers/Location/types';
import { createLeadRequest } from 'api/requests/createLeadRequest';
import { apiCallErrorWithMessage, apiCallSuccessWithMessage } from 'api/utils';
import { apiMessages } from 'api/requests/messages';
import { ButtonSpinner } from 'components/ButtonSpinner';
import { IFormValues } from './types';
import Alert from 'react-bootstrap/Alert';

interface IProps {
  location: ILocation;
}

const FormInner = ({ isSubmitting, status }: FormikProps<IFormValues>) => (
  <div className="form">
    <div className="form-uptitle">
      Сделайте вклад в успешное будущее вашего ребенка!
    </div>
    <h4 className="form-title">
      Запишитесь на <b>бесплатное</b> пробное занятие
    </h4>
    <FormikForm>
      {status && (
        <Alert variant="danger" className="text-center">
          {status}
        </Alert>
      )}
      <FormInput<keyof IFormValues>
        name="name"
        title="Имя"
        placeholder="Введите ваше имя"
      />
      <FormInput<keyof IFormValues>
        type="email"
        name="email"
        title="Email"
        placeholder="Введите ваш email"
      />
      <PhoneInput<keyof IFormValues>
        name="phoneNumber"
        title="Номер телефона"
      />
      <div className="action-btn-wrapper">
        <Button
          disabled={isSubmitting}
          type="submit"
          className="action-btn btn-shine"
          variant="secondary"
          size="lg"
        >
          {isSubmitting ? <ButtonSpinner /> : `Записаться`}
        </Button>
        <img src={arrowLeftImg} alt="Стрелка" />
      </div>
    </FormikForm>
  </div>
);

const withForm = withFormik<IProps, IFormValues>({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    phoneNumber: '',
    courseId: CourseIds.PROGRAMMING,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    phoneNumber: Yup.string().required(),
    courseId: Yup.mixed(),
  }),
  handleSubmit: (
    values,
    { setSubmitting, setErrors, setStatus, resetForm, props },
  ) => {
    createLeadRequest({
      courseId: values.courseId,
      email: values.email,
      name: values.name,
      phoneNumber: values.phoneNumber,
      country: props.location.country,
      city: props.location.city,
      timezone: props.location.timezone,
    })
      .then(
        apiCallSuccessWithMessage(setSubmitting, () => {
          import('sweetalert2').then((Swal) => {
            Swal.default.fire({
              title: 'Заявка отправлена!',
              text: apiMessages.lead.success,
              icon: 'success',
              confirmButtonText: 'ОК',
            });
          });
          resetForm();
        }),
      )
      .catch(apiCallErrorWithMessage(setSubmitting, setErrors, setStatus));
  },
});

export const Form = withForm(FormInner);
