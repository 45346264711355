import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import graduateImg from './images/graduate.svg';
import timeImg from './images/time.svg';
import trophyImg from './images/trophy.svg';
import nextArrowImg from 'assets/images/next_arrow.svg';
import Button from 'react-bootstrap/Button';

interface IProps {
  onActionBtnClick: () => void;
}

export const MainBlock = ({ onActionBtnClick }: IProps) => (
  <section className="main-block container">
    <div className="background-top" />
    <div className="main-proposition">
      <h1 className="title">
        <b>научим</b> вашего ребенка <b>программированию</b> в режиме{' '}
        <b>онлайн</b> занятий
      </h1>
      <div className="subtitle">
        Помогите своему ребенку сделать первый шаг к одной из самых
        высокооплачиваемых профессий в мире!
      </div>
      <div className="info-list">
        <div className="info-item">
          <img src={graduateImg} alt="Выпускник" />
          <span>
            от 6 до
            <br /> 16 лет
          </span>
        </div>
        <div className="info-item">
          <img src={timeImg} alt="Время" />
          <span>в удобное время</span>
        </div>
        <div className="info-item">
          <img src={trophyImg} alt="Трофей" />
          <span>с опытными учителями</span>
        </div>
      </div>
      <div className="main-action">
        <Button
          onClick={onActionBtnClick}
          variant="secondary"
          size="lg"
          className="action-btn btn-shine"
        >
          Записаться
        </Button>
        <img src={nextArrowImg} className="action-img" alt="Стрелка влево" />
        <div className="action-text">
          Запишитесь на <b>бесплатное</b> пробное занятие
        </div>
      </div>
    </div>
    <BackgroundImg />
  </section>
);
