// import tinkercadImg from './images/tinkercad.png';
import scratchImg from './images/scratch.png';
import minecraftImg from './images/minecraft.png';
import pythonImg from './images/python.png';
import webImg from './images/web.png';

export const Courses = {
  // tinkercad: {
  //   name: '3D Tinkercad',
  //   img: tinkercadImg,
  //   points: [
  //     'можно начинать с 8 лет',
  //     'идеальная программа для знакомства с полигональным моделированием',
  //     'обучит созданию моделей, которые вы сможете распечатать на 3D принтере',
  //   ],
  // },
  scratch: {
    name: 'Scratch',
    img: scratchImg,
    points: [
      'отлично подходит для начинающих',
      'развивает логику и алгоритмическое мышление',
      'поможет подготовиться к переходу на высокоуровневый язык программирования',
    ],
  },
  minecraft: {
    name: 'Minecraft\nEducation Edition',
    img: minecraftImg,
    points: [
      'подойдет для учеников 8-14 лет',
      '3D-моделирование и программирование в одном флаконе',
      'развивает алгоритмическое и пространственное мышление, учит использовать математику в программировании',
    ],
  },
  python: {
    name: 'Python',
    img: pythonImg,
    points: [
      'рекомендуется ученикам от 12 лет',
      'познакомит ученика с высокоуровневым программированием',
      'даст понимание того, как применяется программирование в повседневной жизни',
    ],
  },
  web: {
    name: 'Web-программирование',
    img: webImg,
    points: [
      'подойдет для учеников с 12 лет',
      'покажет, как устроена веб-страница изнутри',
      'научит создавать структуру и наполнение веб-сайтов',
    ],
  },
};
