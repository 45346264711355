import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { CourseIds } from 'constants/courses';
import { LocationContext } from 'containers/Location/LocationContext';
import { Form } from './Form';
import './styles.scss';

export interface ILeadModalData {
  opened: boolean;
  courseId?: CourseIds;
}

interface IProps extends ILeadModalData {
  onClose: () => void;
}

export const LeadAskModal = ({ opened, courseId, onClose }: IProps) => {
  const location = React.useContext(LocationContext);
  return (
    <Modal
      className="lead-modal"
      show={opened}
      onHide={onClose}
      backdropClassName="backdrop-light"
      centered
    >
      <Modal.Body>
        <button onClick={onClose} className="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 className="title">
          Оставьте заявку и мы более подробно проконсультируем вас о данном
          курсе!
        </h4>
        <Form courseId={courseId} onSuccess={onClose} location={location} />
      </Modal.Body>
    </Modal>
  );
};
