import React from 'react';
import { AppearSensor } from 'components/AppearSensor';
import { Animation } from 'components/Animation';
import { Fade } from './animation';
import logicImg from './images/logic.png';
import salaryImg from './images/salary.png';
import studyImg from './images/study.png';

export const ImportantBlock = () => (
  <section className="important-block container">
    <div className="subtle-title">Важно</div>
    <h2 className="title">
      Зачем нужно изучать программирование с раннего возраста
    </h2>
    <AppearSensor>
      <div className="point-list row" id="important-block-point-list">
        <Animation
          animation={Fade}
          delay={300}
          className="point-item col-12 col-md-9 col-xl-6"
        >
          <img src={logicImg} alt="Логика" />
          <span>Улучшит логику и креативное мышление</span>
        </Animation>
        <Animation
          animation={Fade}
          delay={800}
          className="point-item col-12 col-md-9 col-xl-6"
        >
          <img src={salaryImg} alt="Зарплата" />
          <span>Позволит работать в профессии с высокой зарплатой</span>
        </Animation>
        <Animation
          animation={Fade}
          delay={1300}
          className="point-item col-12 col-md-9 col-xl-6"
        >
          <img src={studyImg} alt="Обучение" />
          <span>Повысит внимательность и общую успеваемость в школе</span>
        </Animation>
      </div>
    </AppearSensor>
  </section>
);
