import React from 'react';
import { Carousel } from 'components/Carousel';
import arrowLeftImg from './images/arrow_left.inline.svg';
import arrowRightImg from './images/arrow_right.inline.svg';
import { Switch } from './Switch';
import { CourseCard } from './CourseCard';
import { Courses } from './constants';

interface IProps {
  onLeadModalOpen: () => void;
}

export const CoursesBlock = ({ onLeadModalOpen }: IProps) => {
  return (
    <section className="courses-block container">
      <h2 className="title">Наши направления</h2>
      <Carousel
        className="courses-carousel"
        controllerImgLeft={arrowLeftImg}
        controllerImgRight={arrowRightImg}
        defaultActiveIndex={1}
        header={(activeIndex, handleSwitch) => (
          <Switch activeIndex={activeIndex} onClick={handleSwitch} />
        )}
      >
        <div className="row courses-row">
          <div className="col-xxl-4 col-lg-6 course-col">
            <CourseCard
              onActionBtnClick={onLeadModalOpen}
              data={Courses.scratch}
            />
          </div>
          <div className="col-xxl-6 col-lg-6 course-col">
            <CourseCard
              onActionBtnClick={onLeadModalOpen}
              data={Courses.minecraft}
            />
          </div>
        </div>
        <div className="row courses-row">
          <div className="col-xxl-6 col-lg-6 course-col">
            <CourseCard
              onActionBtnClick={onLeadModalOpen}
              data={Courses.scratch}
            />
          </div>
          <div className="col-xxl-6 col-lg-6 course-col">
            <CourseCard
              onActionBtnClick={onLeadModalOpen}
              data={Courses.minecraft}
            />
          </div>
          <div className="col-xxl-6 col-lg-6 course-col">
            <CourseCard
              onActionBtnClick={onLeadModalOpen}
              data={Courses.python}
            />
          </div>
          <div className="col-xxl-6 col-lg-6 course-col">
            <CourseCard onActionBtnClick={onLeadModalOpen} data={Courses.web} />
          </div>
        </div>
      </Carousel>
    </section>
  );
};
