import { keyframes } from '@emotion/core';

export const Fade = keyframes`
  0% {
    transform: translate3d(-200px, -100px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;
