import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import { Form } from './Form';
import { LocationContext } from 'containers/Location/LocationContext';

export const FormBlock = () => {
  const location = React.useContext(LocationContext);

  return (
    <section className="form-block container">
      <Form location={location} />
      <BackgroundImg />
    </section>
  );
};
