import React from 'react';
import { Animation } from 'components/Animation';
import { AppearSensor } from 'components/AppearSensor';
import calendarImg from './images/calendar.svg';
import coffeeImg from './images/coffee.svg';
import supportImg from './images/support.svg';
import { BackgroundImg } from './BackgroundImg';
import { Fade } from './animation';

export const ConvenienceBlock = () => {
  return (
    <section className="convenience-block container">
      <div className="subtle-title">Удобство</div>
      <h2 className="title">Как проходит обучение</h2>
      <AppearSensor offset={100}>
        <div className="point-list">
          <Animation animation={Fade} delay={300} className="point-item">
            <img className="point-img" src={coffeeImg} alt="Чашка кофе" />
            <h4 className="point-title">Занятия онлайн</h4>
            <div className="point-text">
              Все уроки проходят в комфортной домашней обстановке и вы не
              тратите время на дорогу
            </div>
          </Animation>
          <BackgroundImg />
          <Animation animation={Fade} delay={800} className="point-item">
            <img className="point-img" src={supportImg} alt="Обратная связь" />
            <h4 className="point-title">Обратная связь</h4>
            <div className="point-text">
              Если у вас возникнут вопросы, вы можете написать нам в любое время
              суток
            </div>
          </Animation>
          <Animation animation={Fade} delay={1300} className="point-item">
            <img className="point-img" src={calendarImg} alt="Календарь" />
            <h4 className="point-title">Удобный график</h4>
            <div className="point-text">
              Подберем самый оптимальный график обучения и в удобном для Вас
              темпе
            </div>
          </Animation>
        </div>
      </AppearSensor>
    </section>
  );
};
