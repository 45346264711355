import React from 'react';
import Button from 'react-bootstrap/Button';
import arrowLeftImg from './images/next_arrow.png';

interface IProps {
  onActionBtnClick: () => void;
}

export const PleaForAction = ({ onActionBtnClick }: IProps) => (
  <div className="plea-for-action">
    <div className="action-text">
      Запишитесь на <b>бесплатное</b> пробное занятие и убедитесь во всем сами !
    </div>
    <div className="action-btn-wrapper">
      <Button
        onClick={onActionBtnClick}
        size="lg"
        variant="secondary"
        className="action-btn btn-shine"
      >
        Записаться
      </Button>
      <img src={arrowLeftImg} alt="Стрелка влево" />
    </div>
    <div className="action-background" />
  </div>
);
