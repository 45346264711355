import React from 'react';
import Button from 'react-bootstrap/Button';
import { ICourse } from './types';

interface IProps {
  data: ICourse;
  onActionBtnClick: () => void;
}

export const CourseCard = ({ data, onActionBtnClick }: IProps) => (
  <div className="course-card">
    <div className="course-img">
      <img src={data.img} alt="Курс" />
    </div>
    <h4 className="title">{data.name}</h4>
    <ul className="course-point-list">
      {data.points.map((text, index) => (
        <li key={index}>{text}</li>
      ))}
    </ul>
    <Button
      onClick={onActionBtnClick}
      size="lg"
      variant="secondary"
      className="action-btn btn-shine"
    >
      Подробнее
    </Button>
  </div>
);
