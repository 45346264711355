import React from 'react';
import { Animation } from 'components/Animation';
import { AppearSensor } from 'components/AppearSensor';
import likeRedImg from './images/like_red.svg';
import likeBlueImg from './images/like_blue.svg';
import likeYellowImg from './images/like_yellow.svg';
import { BackgroundImg } from './BackgroundImg';
import { PleaForAction } from './PleaForAction';
import { AppearAndWobble } from './animation';

interface IProps {
  onLeadModalOpen: () => void;
}

export const TrustBlock = ({ onLeadModalOpen }: IProps) => (
  <section className="trust-block container">
    <div className="point-list-wrapper">
      <BackgroundImg />
      <AppearSensor offset={200}>
        <div className="point-list">
          <div className="subtle-title">Доверие</div>
          <h2 className="title">Почему вам понравится у нас</h2>
          <div className="point-item">
            <Animation
              visible
              duration={1600}
              delay={300}
              tag="img"
              src={likeRedImg}
              alt="Лайк"
              className="point-img"
              animation={AppearAndWobble}
            />
            <span>
              Нескучная теория, которую можно сразу применить на практике
            </span>
          </div>
          <div className="point-item point-item-space">
            <Animation
              visible
              duration={1600}
              delay={800}
              tag="img"
              src={likeBlueImg}
              alt="Лайк"
              className="point-img"
              animation={AppearAndWobble}
            />
            <span>Собственные игры, в которые можно поиграть с друзьями</span>
          </div>
          <div className="point-item">
            <Animation
              visible
              duration={1600}
              delay={1300}
              tag="img"
              src={likeYellowImg}
              alt="Лайк"
              className="point-img"
              animation={AppearAndWobble}
            />
            <span>
              Опытные преподаватели, которые любят общаться и найдут подход к
              любому ученику
            </span>
          </div>
          <div className="point-item">
            <Animation
              visible
              duration={1600}
              delay={1800}
              tag="img"
              src={likeRedImg}
              alt="Лайк"
              className="point-img"
              animation={AppearAndWobble}
            />
            <span>Сертификат в конце обучения</span>
          </div>
        </div>
      </AppearSensor>
    </div>
    <PleaForAction onActionBtnClick={onLeadModalOpen} />
  </section>
);
