import React from 'react';
import { SEO } from 'components/SEO';
import { MainBlock } from './MainBlock';
import { ImportantBlock } from './ImportantBlock';
import { TrustBlock } from './TrustBlock';
import { CoursesBlock } from './CoursesBlock';
import { ConvenienceBlock } from './ConvenienceBlock';
import { PriceBlock } from './PriceBlock';
import { ReviewBlock } from './ReviewBlock';
import { FormBlock } from './FormBlock';
import { LeadModal } from 'containers/Modals/LeadModal';
import { LeadHookModal } from 'containers/Modals/LeadHookModal';
import { LeadAskModal } from 'containers/Modals/LeadModal/LeadAskModal';
import { CourseIds } from 'constants/courses';
import './styles.scss';

const Programming = () => {
  const [stopHookTimer, setStopHookTimer] = React.useState(false);
  const [leadModalOpened, setLeadModalOpened] = React.useState(false);
  const [leadModalAskOpened, setLeadAskModalOpened] = React.useState(false);
  const onLeadModalClose = React.useCallback(() => {
    setLeadModalOpened(false);
  }, []);
  const onLeadAskModalClose = React.useCallback(() => {
    setLeadAskModalOpened(false);
  }, []);
  const onLeadModalOpen = React.useCallback(() => {
    setStopHookTimer(true);
    setLeadModalOpened(true);
  }, []);
  const onLeadAskModalOpen = React.useCallback(() => {
    setStopHookTimer(true);
    setLeadAskModalOpened(true);
  }, []);

  return (
    <main className="page programming-page">
      <SEO
        landing
        title="Онлайн-школа программирования для детей | UP! School "
        description="Обучение программированию детей от 6 лет в лучшей школе программирования. Курсы по Scratch (Скретч), Javascript, Python, Майнкрафт, Web-программирование. С нуля и для начинающих."
        meta={[
          {
            name: `keywords`,
            content:
              'Онлайн-школа программирования для детей, уроки программирования онлайн',
          },
        ]}
      />
      <MainBlock onActionBtnClick={onLeadModalOpen} />
      <ImportantBlock />
      <TrustBlock onLeadModalOpen={onLeadModalOpen} />
      <CoursesBlock onLeadModalOpen={onLeadAskModalOpen} />
      <ConvenienceBlock />
      <PriceBlock onLeadModalOpen={onLeadModalOpen} />
      <ReviewBlock />
      <FormBlock />
      <LeadHookModal stopTimer={stopHookTimer} />
      <LeadModal
        opened={leadModalOpened}
        courseId={CourseIds.PROGRAMMING}
        onClose={onLeadModalClose}
      />
      <LeadAskModal
        opened={leadModalAskOpened}
        courseId={CourseIds.PROGRAMMING}
        onClose={onLeadAskModalClose}
      />
    </main>
  );
};

export default Programming;
