import React from 'react';
import { PriceCard } from 'components/PriceCard';
import { IPackage } from 'types/price';

export interface IProps {
  pack: IPackage;
  currency: string;
  variant: 'primary' | 'secondary' | 'danger';
  onActionBtnClick: () => void;
}

export const PriceRow = ({
  pack,
  currency,
  variant,
  onActionBtnClick,
}: IProps) => (
  <div className="row price-row">
    {pack.prices.map((packagePrice, index) => (
      <div key={index} className="col-lg-6 col-xxl-4 price-col">
        <PriceCard
          currency={currency}
          price={packagePrice}
          packageSize={4 * (index + 1)}
          variant={variant}
          onActionBtnClick={onActionBtnClick}
          shine
        />
      </div>
    ))}
  </div>
);
