import { keyframes } from '@emotion/core';

export const AppearAndWobble = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.01);
  }

  30% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    transform: translate3d(-25%,0,0) rotate(-5deg);
  }

  60% {
    transform: translate3d(20%,0,0) rotate(3deg);
  }

  70% {
    transform: translate3d(-15%,0,0) rotate(-3deg);
  }

  80% {
    transform: translate3d(10%,0,0) rotate(2deg);
  }

  90% {
    transform: translate3d(-5%,0,0) rotate(-1deg);
  }

  100% {
    transform: translateZ(0);
  }
`;
