import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { LocationContext } from 'containers/Location/LocationContext';
import shareImg from './images/share.png';
import { Form } from './Form';
import './styles.scss';

interface IProps {
  stopTimer: boolean;
}

export const LeadHookModal = ({ stopTimer }: IProps) => {
  const location = React.useContext(LocationContext);
  const [isOpen, setOpen] = React.useState(false);
  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 30000);

    if (stopTimer) {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [stopTimer]);

  return (
    <Modal
      className="lead-hook-modal"
      show={isOpen}
      onHide={onClose}
      backdropClassName="backdrop-light"
      centered
    >
      <Modal.Body>
        <button onClick={onClose} className="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 className="title">
          Запишитесь на <b>бесплатное</b> пробное занятие!
        </h4>
        <div className="lead-hook-modal-content">
          <div className="background-left" />
          <img src={shareImg} alt="Стрелочка" className="share-img" />
          <Form location={location} onSuccess={onClose} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
