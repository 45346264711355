import React from 'react';
import { ReviewModal } from 'containers/Modals/ReviewModal';
import { IReview } from 'types/review';
import { reviews } from 'constants/reviews';
import { CourseIds } from 'constants/courses';
import { ReviewCard } from './ReviewCard';

export const ReviewBlock = () => {
  const [reviewModal, setReviewModal] = React.useState<{
    isOpen: boolean;
    data?: IReview;
  }>({
    isOpen: false,
    data: undefined,
  });

  const onReviewClose = React.useCallback(() => {
    setReviewModal({ isOpen: false });
  }, []);

  const onReviewOpen = React.useCallback((data) => {
    setReviewModal({ isOpen: true, data });
  }, []);

  return (
    <section className="review-block container">
      <h2 className="title">Отзывы наших учеников и их родителей</h2>
      <div className="review-list row">
        {reviews
          .filter((review) => review.courseId === CourseIds.PROGRAMMING)
          .slice(0, 4)
          .map((review, index) => (
            <div key={index} className="review-item col-12 col-sm-10 col-lg-6">
              <ReviewCard onExpand={onReviewOpen} data={review} />
            </div>
          ))}
      </div>
      {reviewModal.data && (
        <ReviewModal
          isOpen={reviewModal.isOpen}
          onClose={onReviewClose}
          data={reviewModal.data}
        />
      )}
    </section>
  );
};
