import React, { SyntheticEvent } from 'react';
import cx from 'classnames';

interface IProps {
  activeIndex: number;
  onClick: (index: number) => void;
}

export const Switch = ({ activeIndex, onClick }: IProps) => {
  const handleClick = React.useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      const index = e.currentTarget.getAttribute('data-index') as string;
      onClick(parseInt(index, 10));
    },
    [],
  );

  return (
    <div className="courses-switch">
      <button
        data-index={0}
        onClick={handleClick}
        className={cx('switch-control switch-control-primary', {
          active: activeIndex === 0,
        })}
      >
        6-12 лет
      </button>
      <button
        data-index={1}
        onClick={handleClick}
        className={cx('switch-control switch-control-danger', {
          active: activeIndex === 1,
        })}
      >
        12-16 лет
      </button>
    </div>
  );
};
